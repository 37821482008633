<template>
 <ion-page>
   <ion-header>
     <ion-toolbar color="warning" v-if="priority == 1">
       <ion-buttons slot="end">
            <ion-button @click="dismiss()">閉じる</ion-button>
        </ion-buttons>
        <ion-title><h1>{{title}}</h1></ion-title>
     </ion-toolbar>
     <ion-toolbar color="tertiary" v-else>
       <ion-buttons slot="end">
            <ion-button v-if="!isRef" @click="read()">閉じる</ion-button>
            <ion-button v-else @click="dismiss()">閉じる</ion-button>
        </ion-buttons>
        <ion-title>{{title}}</ion-title>
     </ion-toolbar>
   </ion-header>
   <ion-content :fullscreen="true" class="ion-padding">
    <!-- 回答つき -->
    <ion-list v-if="priority==1">
      <ion-item-divider>
        <ion-label>質問</ion-label>
      </ion-item-divider>
      <ion-text>
        <h3 class="nl2br ion-padding">{{message}}</h3>
      </ion-text>
      <!-- 添付画像 -->
      <ion-item-divider v-if="imageURL != null">
       <ion-label>添付画像</ion-label>
      </ion-item-divider>
      <ion-text v-if="imageURL != null" >
           <ion-router-link id="image" target="_blank" :href="imageURL">
             <h3 class="ion-padding">
               <ion-icon :icon="documentAttachOutline"></ion-icon>
               添付画像
             </h3>
           </ion-router-link>
      </ion-text>
      <ion-item-divider>
      <ion-label>回答</ion-label>
      </ion-item-divider>
      <ion-item v-if="!isRef"> 
       <ion-textarea class="memo" :disable="isDisable" v-model="memo" rows="6" cols="20" placeholder="備考がある場合はここに入力"></ion-textarea>
      </ion-item>
      <ion-text v-else>
        <h3 class="ion-padding">回答済みです</h3>
      </ion-text>
      <ion-grid v-if="!isRef">
        <ion-row class="ion-justify-content-between">
          <ion-button shape="round" size="large" color="danger"  @click="poll('no')">いいえ</ion-button>
          <ion-button shape="round" size="large" color="primary" @click="poll('yes')">はい</ion-button>
        </ion-row>
      </ion-grid>
    </ion-list>
    <!--普通のお知らせ -->
      <ion-list v-else>
        <ion-item-divider>
          <ion-label>おしらせ</ion-label>
        </ion-item-divider>
        <ion-text>
           <h3 class="ion-padding nl2br">{{message}}</h3>
         </ion-text>
         <!--通常: 添付画像 -->
         <ion-item-divider v-if="imageURL != null">
           <ion-label>添付画像</ion-label>
         </ion-item-divider>
         <ion-text v-if="imageURL != null" >
           <ion-router-link id="image" target="_blank" :href="imageURL">
             <h3 class="ion-padding">
               <ion-icon :icon="documentAttachOutline"></ion-icon>
               添付画像
             </h3>
           </ion-router-link>
         </ion-text>
    </ion-list>      
  </ion-content>
 </ion-page>
</template>

<script>
import {
IonLabel, 
IonPage,
IonHeader, 
IonTitle, 
IonToolbar, 
IonContent,
IonButtons,
IonButton,
IonTextarea,
IonItemDivider,
IonItem,
IonList,
IonIcon,
IonText,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { documentAttachOutline } from 'ionicons/icons';
export default defineComponent({
  name: 'Modal',
  props: {
    //このProperty群はModalを開く時に埋められる。
    title: { type: String, default: '---' },
    message : { type: String, default: '---'},
    priority : {type: String, default: '---'},
    userId : {type: String, default: null},
    guardianId: {type: String, default: null},
    userName : {type: String, default: '---'},
    originalDocId : {type: String, default: '---'},
    YYYYMMDD : {type: String, default: '---'},
    docId : null,
    tenantId : null,
    range : null,
    targetUserIds : null,
    targetClass : null,
    isRead : null,
    imageURL : {type: String, default: null},
    isRef : {type: Boolean, default: false},
    isDisable: {type: Boolean, default: false},
    createdAt : null,
  },
  setup() {
    return {
      documentAttachOutline
    }
  },
  data() {
    return {
      memo : '',
    }
  },
  mounted(){
  },
  methods: {
    async dismiss(){
        const modal = this.$store.state.modal;
        await modal.dismiss();
    },
    async poll(answer){
       this.$root.startLoading();
       await this.pollInternal(answer);
       this.$root.endLoading();
       this.$router.go({path: this.$router.currentRoute.path, force: true});
    },
    async pollInternal(answer){
      console.log(answer);
      let msg = answer == "yes" ? '質問への答えは「はい」' : '質問への答えは「いいえ」';
      if(this.memo != ''){
       msg += `備忘：「${this.memo}」`;
      }
      if(window.confirm(`${msg}で送信してよろしいですか？`)){
        const params = {
          'tenantId' : this.tenantId,
          'title' : this.title,
          'message' : this.message,
          'guardianId' : this.guardianId,
          'userId' : this.userId,
          'userName' : this.userName,
          'originalDocId' : this.originalDocId,
          'answer' : answer,
          'YYYYMMDD' : this.YYYYMMDD,
          'range' : this.range,
          'targetUserIds' : this.targetUserIds,
          'targetClass' : this.targetClass,
          'memo': this.memo,
          'createdAt' : this.createdAt 
        };
        const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
        const POLL = FUNCTIONS_HOST_NAME + "/poll";
        await this.axios.post(POLL,params).catch(error => {
          alert(error);
        });
        await this.readInternal();
        await this.dismiss();
      }
    },
    async readInternal(){
        const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
        const READ = FUNCTIONS_HOST_NAME + "/notification/read";
        if(this.docId){
          await this.axios.post(READ,{
             "docId" : this.docId,
             "userId" : this.userId,
             "tenantId" : this.tenantId,
             "guardianId" : this.guardianId,
             "isFamily" : this.range == "3"
          }).catch(error => {
            alert(error);
          });
        }else{
          alert('cannot update read status');
        }
        await this.dismiss();
    },
    async read(){
       this.$root.startLoading();
       await this.readInternal();
       this.$root.endLoading();
       this.$router.go({path: this.$router.currentRoute.path, force: true});
    }
  },
  components: { 
   IonLabel,
   IonPage,
   IonHeader, 
   IonTitle, 
   IonToolbar,
   IonContent,
   IonButtons,
   IonButton,
   IonTextarea,
   IonItemDivider,
   IonItem,
   IonList,
   IonIcon,
   IonText
}
});
</script>
<style scoped>
 .memo{
   padding: 10px;
   font-size: 16pt;
 }
 .nl2br{
   white-space: pre-line;
 }
</style>