const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
const ALL_NOTIFICATIONS = FUNCTIONS_HOST_NAME + "/notification/all";

/**
 * @function firestoreへアクセス
 * @param {object} axios : axiosインスタンス
 * @param {string} tenantId
 * @param {string} userId
 * @param {string} guardianId
 * @return {Array of Notification Object}  
 */
export default async function getNotifications(axios,tenantId,userId,guardianId,isRead) {
    let notifications = [];
    let params = { "params" : {
      'tenantId' : tenantId,
      'userId' : userId,
      'guardianId' : guardianId
    }};
    let response = await axios.get(ALL_NOTIFICATIONS,params);
    response.data.forEach(notification => {
      if(isRead){
        if(notification.read){
          notifications.push(notification);
        }else{
          console.log('skip due to SEARCH mode');
        }
      }else{
        if(!notification.read){
          notifications.push(notification);
        }else{
          console.log('skip due to NEW mode');
        }
      }
    });
    //日付の加工
    notifications.forEach(element => {
      let displayDate = element.YYYYMMDD;
      let yyyy = displayDate.substring(0,4);
      if(displayDate.slice("-5").startsWith("0")){
        displayDate = displayDate.slice(-4);
      }else{
        displayDate = displayDate.slice(-5);
      }
      displayDate = yyyy + "/" + displayDate.replace("-","/");
      element.displayDate = displayDate;
    });
    //2件以上あるならソートして返す
    if(notifications.length >= 2){
      notifications.sort(function(a, b) {
      return b.createdAt - a.createdAt;
      });
    }
    return notifications;
}